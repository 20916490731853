
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap');

.flashcard {
  perspective: 1000px;
  width: 360px;
  height: 240px;
  background-color: transparent;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0);
}

.flashcardMobile {
  perspective: 1000px;
  width: 90vw;
  height: 60vw;
  background-color: transparent;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0);
}

.flashcardMobileAddCard {
  perspective: 1000px;
  width: 90vw;
  height: 28vw;
  background-color: transparent;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0);
}

.card {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  /* transition: transform 0.6s; */
  cursor: pointer;
}

.cardAnimate {
  transition: transform 0.6s;
}

.cardNoAnimate {
  transition: transform 0s;
}

.card.flipped {
  transform: rotateY(180deg);
}

.front, .back {
  font-family: 'Fira Sans';
  font-weight: 400;
  padding: 20px;
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  color: #1f1f1f;
  border-radius: 20px;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
}

.front {
  background-color: #FFFFFF;
}

.back {
  background-color: #FFFFFF;
  transform: rotateY(180deg);
}


/* Flashcard.css */
.animated-border::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -2px; /* Align it with the bottom of the input */
  left: 0;
  width: 0;
  height: 2px;
  background-color: black; /* Color of the animated border */
  transition: width 0.3s ease-in-out;
}

.animated-border:focus-within::after,
.animated-border:focus::after {
  width: 100%;
}

.animated-border {
  position: relative;
}
