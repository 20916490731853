body {
  background-color: #edf2fb;
  margin: 0;
  padding: 0;
}

.gradient-text {
  background: linear-gradient(to right, #4299e1, #48bb78);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  overflow: visible;
}

.spinner {
  border: 4px solid #1f1f1f;
  border-top: 4px solid transparent; 
  border-left: 4px solid transparent;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  animation: spin 1.4s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
